.paymentStoppage {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  > .topRow {
    width: 100%;
    margin: 0.5rem 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
  }

  > .bottomRow {
    width: 100%;
    margin: 0.5rem 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > img {
      cursor: pointer;
    }
  }
}
