@import "../../../../assets/themes/default/variables";

.info {
  margin: 0px 1rem 1.8rem 1rem;
}

.title {
  margin: 0px 1rem 0rem 1rem;
  color: $custom-grey1
}


.radios {
  margin: 0.7rem 1rem 1.5rem 1rem;
  display: flex;
  > * {
    margin-right: 1rem;
  }
}

.dates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  .dateSelect {
    margin: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      margin: 0px 0.25rem;
    }
  }
}
