.wrapper-message-switcher {
  border-bottom: 1px solid #e3e5e7;

  button {
    display: inline-block;
    background-color: transparent;
    border: 0px;
    outline: 0;
    border-bottom: 4px solid transparent;
    line-height: 68px;
    padding: 0 12px;
    font-size: 16px;
    color: #677078;

    &:last-child {
      margin-left: 10px;
    }

    &:focus {
      outline: 0px;
    }

    &.active {
      border-bottom: 4px solid #004b93;
      color: black;
    }
  }
}

.messages-list {
  .paging-info {
    margin: 10px !important;
  }
}

.card.message-body-card {
  padding: 2rem;
}

span.info {
  color: #8d949a;
}

span.badge.messages {
  border-radius: 5px;
  margin-left: 5px;
  padding: 5px 7px;
}

table.table.messages {
  thead {
    tr {
      th.column-date {
        width: 110px;
      }
      th.column-depo {
        width: 100px;
      }

      th {
        span.column-container {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;

      &.unread {
        font-weight: bold;
        background-color: #f5f8fb;

        td:first-child {
          border-left: 5px solid #014b93;
        }
      }
    }
  }
}

.message-text {
  margin: 1.5rem 0;
}

.check-list {
  padding-left: 0.5em;
  padding-right: 0.5em;
  max-height: 250px;
  overflow: auto;

  .custom-control,
  .form-control {
    margin-bottom: 0.5em;
  }
}

.full-width {
  width: 100%;
}
