$dropdown-item-color: #717981;
$dropdown-item-bg: #004b93;
$dropdown-item-active-text-color: white;

table.parcels {
  tr {
    td.options, th.options {
      max-width: 85px;
      width: 85px;
    }
    td.product, th.product {
      max-width: 110px;
      width: 110px;
    }
    &.warning {
      background-color: rgba(212, 5, 17, 0.08);
      button.dropdown-toggle {
        background-color: #ffffff;
      }
    }
  }

  button.dropdown-toggle.btn:focus {
    box-shadow: none;
  }

  button.dropdown-toggle.btn:active,
  button.dropdown-toggle.btn:hover,
  .show > .btn-outline-secondary.dropdown-toggle {
    background-color: #014b93;
    outline: none;
    box-shadow: none;

    polygon {
      fill: #014b93;
    }

    path {
      fill: white;
    }
  }
}

a.dropdown-item {
  color: $dropdown-item-color;
}

td.options {
  padding-left: 25px;

  button {
    path {
      transform: rotate(90deg) translate(-7px, -17px);
    }
    &:after {
      display: none;
    }
  }
}

.nested-nav-items {
  &.nav-item {
    a {
      color: $dropdown-item-color;
      padding: 0.25rem 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after {
        height: 0.2rem;
      }

      &:hover {
        color: #16181b;
        background-color: #e9ecef;
      }
    }
  }

  .dropdown-menu {
    .dropdown-item {
      &:active {
        color: $dropdown-item-active-text-color;
        background-color: $dropdown-item-bg;
      }
    }
  }
}
