.badge {
  color: #1F2326;
  font-weight: normal;
  padding: 8px 10px;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;

  &.blue {
    background-color: #D6E6F5;
    border-color: #BED6EF;
  }

  &.green {
    background-color: #D6EADF;
    border-color: #BDDCCB;
  }

  &.orange {
    background-color: #FCECD6;
    border-color: #FADEBD;
  }

  &.yellow {
    background-color: #FFF4CB;
    border-color: #FFEB94;
  }

  &.red {
    background-color: #F8D7D8;
    border-color: #F3B4B9;
  }
}

.header-row.row {
  .badge {
    vertical-align: middle;
    margin: 0px 0px 5px 15px;
  }
}

.dot {
  height: 4px;
  width: 4px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 15px;
}

th.terminal_status, td.terminal_status {
  width: 260px;
}

td.terminal_status {
  white-space: nowrap;
  padding: 0px 10px;
  color: #838B91;
}
