.header-row {
  font-weight: 300;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  align-items: center;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  .btn-toolbar {
    margin: 0;
    justify-content: flex-end;
  }
}
