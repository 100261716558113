.wizard-steps .nav-item .nav-link.light-blue {
  background: rgba(0, 75, 147, 0.06);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004B93;
}

.check-rectangle {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid rgba(0, 75, 147, 0.4);
  box-shadow: 0px 1px 0px rgba(0, 75, 147, 0.1);
  border-radius: 3px;
  margin-right: 8px;
  padding: 5px;
}

.opportunity-statuses .nav-item a {
  min-height: 83px;
  cursor: auto;
}

.opportunity-statuses .nav-item .active {
  font-weight: 500;
  font-size: 16px;
}

.column-container {
  display: flex;
  justify-content: center;
}

.opportunities .edit {
  width: 100px;
  text-align: center;
}

.opportunities .vm {
  width: 200px;
}