$custom-grey1: #677078;
$custom-grey4: #e3e5e7;
$custom-grey5: #f0f1f2;
$custom-grey6: #f9f9fa;

$blue: #004b93 !default;
$blue-dark: #003160 !default;
$black: #1f2226 !default;
$red: #d40511 !default;
$yellow: #ffcc00 !default;
$green: #007c39 !default;

$warning: $yellow !default;

$table-accent-bg: $custom-grey6 !default;

// Components
$border-radius: 0.5rem !default;
$border-radius-lg: 0.6rem !default;
$border-radius-sm: 0.4rem !default;

// Checkbox
$custom-control-indicator-bg-size: 60%;
$custom-checkbox-indicator-border-radius: 0.25rem !default;
$custom-checkbox-indicator-icon-checked: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiAgIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgICB2ZXJzaW9uPSIxLjEiICAgd2lkdGg9IjFlbSIgICBoZWlnaHQ9IjFlbSIgICB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+ICA8cGF0aCAgICAgZD0iTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeiIgICAgIGZpbGw9IiNmZmYiIC8+PC9zdmc+') !default;

// Font
$line-height-base: 1.2 !default;
$headings-font-weight: 300 !default;

$web-font-path: "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700" !default;
$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-size-base: 1rem !default;

// Typography
$list-inline-padding: 1rem !default;

// Card
$card-spacer-x: 2.5rem !default;

// Forms
$input-btn-padding-y: 0.5rem !default;

$label-margin-bottom: 0.3rem !default;
$form-group-margin-bottom: 1.2rem !default;
$form-check-input-margin-y: 0.2rem !default;

// Navbar
$navbar-padding-x: 3rem !default;
$navbar-padding-y: 0 !default;

$nav-link-padding-x: 1rem !default;
$navbar-nav-link-padding-x: 1rem !default;

$nav-link-padding-y: 1.2rem !default;
$navbar-brand-padding-y: 0 !default;

// Breadcrumb
$breadcrumb-font-size: 90% !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-padding-y: 1rem !default;
$breadcrumb-divider: quote(">") !default;

$breadcrumb-bg: none;

// Alerts
$alert-bg-level: -12 !default;
$alert-border-level: -4 !default;
$alert-color-level: 8 !default;

$invalid-feedback-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%23D40511" d="M8 1C4.136 1 1 4.136 1 8s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7z"/> <path fill="%23FFF" d="M8.7 11.5H7.3v-1.4h1.4v1.4zm0-2.8H7.3V4.5h1.4v4.2z"/></svg>') !default;
$form-feedback-icon-invalid: none !default;

$theme-colors: (
  "white": #fff
) !default;
