table.table-opening-hours {
  width: auto;

  input {
    width: 6em;

    &[type="checkbox"] {
      width: initial;
    }
  }
}
