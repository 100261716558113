@import "../assets/themes/default/variables";

$overlay-transparency: .5;

.local-loading-overlay {
  position: relative;
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    &.primary {
      background-color: rgba($blue, $overlay-transparency);
    }
    &.dark {
      background-color: rgba($black, $overlay-transparency);
    }
    &.light {
      background-color: rgba($custom-grey4, $overlay-transparency);
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
