@import "../default/variables";

$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: -12;

.alert-bar {
  margin-top: 2.5rem;
  margin-bottom: -0.5rem;

  @each $color, $value in $theme-colors {
    .alert-#{$color} {
      @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
    }
  }

  .close {
    opacity: 1;

    &:hover {
      color: $white;
      opacity: 0.6;
    }
  }

  .alert-success {
    padding-left: 3rem;
    &:before {
      content: url("../../icons/icon-check-white.svg");
      position: absolute;
      left: 0.6rem;
      top: 0.6rem;
    }
  }

  .alert-danger {
    padding-left: 3rem;
    &:before {
      content: url("../../icons/icon-error-white.svg");
      position: absolute;
      left: 0.6rem;
      top: 0.6rem;
    }
  }
}
