@import "./default/mixins";
@import "./default/variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "./components";
@import url($web-font-path);
@import "~bootstrap/scss/mixins/table-row";

// General
.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-light {
  background: adjust-color($blue, $alpha: -0.94);
  color: $blue;

  &.btn-lg.btn-block {
    padding: 1rem;
  }
}

.btn-white {
  border-color: gray("400");
}

.btn-link.inline {
  font-size: inherit;
  border: 0;
  padding: 0;
}

.button-icon {
  @include override-btn-outline-background("secondary", $gray-200);
  @include override-btn-outline-background("danger", mix($danger, $white, 10));
}

.border-dashed {
  border-style: dashed !important;
}

label {
  color: $custom-grey1;
}

.spinner-border {
  display: block;
  margin: 1rem auto;
}

dl.list-inline {
  dt {
    display: inline;
    font-weight: normal;
    color: $custom-grey1;
    padding-right: 0.5em;

    &:after {
      content: ":";
    }
  }
  dd + dt {
    &:before {
      content: "•";
      padding: 0 1em;
    }
  }

  dd {
    display: inline;
    font-weight: bold;
  }
}

.month-picker-container {
  display: flex;
  flex-direction: column;
  padding-left: 8px;

  .month-picker {
    display: flex;
    justify-content: space-between;

    & > span {
      margin: 8px 8px 0;
    }

    select {
      display: inline-block;
      width: 5.5rem;
    }
  }
}

dl.list-large-value {
  dt {
    color: $custom-grey1;
    font-weight: normal;
  }

  dd {
    font-weight: 500;
    font-size: 20px;
  }
}

// Layout

html,
body,
#root {
  height: 100%;
  background: $custom-grey6;
}

#root {
  display: flex;
  flex-direction: column;
}

#root-content {
  flex: 1 0 auto;
  padding-bottom: 2.5rem;
  min-height: calc(100vh - 130px);
}

$navbar-height: 60px;

.navbar:not(.footer) {
  box-shadow: 0 1px 2px 0 rgba($custom-grey4, 0.4), inset 0 -1px 0 0 $custom-grey4;

  & + * {
    margin-top: $navbar-height;
  }

  .nav-link {
    font-weight: 500;
    height: $navbar-height;

    display: flex;
    align-items: center;
  }

  .nav-link.active {
    border-bottom: 4px solid $blue;
  }

  .show.dropdown.nav-item {
    .dropdown-toggle.nav-link {
      border-bottom: 4px solid $blue;
    }
  }

  .navbar-right {
    padding: 0.8rem 0 0.8rem 1.2rem;
    border-left: 1px solid $custom-grey4;
    font-size: 14px;
  }

  .navbar-text {
    padding: 0;
  }
}

.navbar-expand > .container {
  padding: 0 15px;
}

.breadcrumb-container {
  background: darken($white, 1%);
  border-bottom: 1px solid $custom-grey4;
  z-index: 100;
  position: fixed;
  width: 100%;
}

.show-test-label nav.breadcrumb-container + #root-content {
  padding-top: 130px;
}

nav.breadcrumb-container + #root-content {
  padding-top: 110px;
}

.show-test-label #root-content {
  padding-top: 30px;
}

#root-content {
  padding-top: 10px;
}

.navbar.footer {
  margin-top: auto;
  border-top: 1px solid $custom-grey4;
  display: block;
  height: 70px;

  font-size: 90%;
  .navbar-text {
    padding: 1rem 0;
  }
}

.button-logout {
  margin-left: 1em;
}

h3 {
  font-size: 1.5rem;
  margin: 1.5rem 0 1rem;
  font-weight: 500;
}

.table-form {
  th {
    font-weight: normal;
    color: $custom-grey1;
  }

  th,
  td {
    padding: 0.2rem 2rem 0.2rem 0;
  }
}

.react-bootstrap-table,
.table-wrapper {
  margin: 1rem 0;
  background-color: $white;
  overflow: hidden;
  border: 1px solid $custom-grey4;
  border-radius: $border-radius;

  .table {
    font-size: 0.9rem;
    margin: 0;

    &.clickable {
      tr {
        cursor: pointer;
      }
    }

    th {
      border-top: none;
    }

    td {
      vertical-align: baseline;
    }
  }

  tr.filter-header {
    line-height: 30px;
  }

  th {
    .order-4,
    .caret-4-asc,
    .caret-4-desc,
    .caret-4-no {
      display: inline-block;
      width: 1em;
      vertical-align: bottom;

      &::before {
        display: none;
      }
      &::after {
        margin-left: 3.5px;
        opacity: 1 !important;
        content: url("../icons/icon-sorting.svg") !important;
      }
    }

    .caret-4-asc::after {
      content: url("../icons/icon-sorting-up.svg") !important;
    }

    .caret-4-desc::after {
      content: url("../icons/icon-sorting-down.svg") !important;
    }
  }
}

@include table-row-variant("invalid", #F9F1F3, rgba(212, 5, 17, 0.24));

.table-invalid {
  th:first-child,
  td:first-child {
    border-left: 5px solid $danger;
  }
}

.custom-table {
  thead th {
    vertical-align: middle;

    &.sortable {
      padding-left: 1.75rem;
      position: relative;
    }
  }

  .sort-indicator {
    display: flex;
    position: absolute;
    left: 0.75rem;
    top: 0;
    height: 100%;
    align-items: center;

    &::after {
      opacity: 1;
      content: url("../icons/icon-sorting.svg");
    }

    &.asc::after {
      content: url("../icons/icon-sorting-up.svg");
    }

    &.desc::after {
      content: url("../icons/icon-sorting-down.svg");
    }
  }
}

.btn-toolbar {
  margin-top: 1rem;

  button + button {
    margin-left: 1rem;
  }
}

.paging-info {
  margin-top: 1rem;
}

.form-group {
  &.required {
    .form-label {
      &:after {
        content: "*";
        padding-left: $spacer * 0.25;
        color: $red;
      }
    }
  }
}

.alert {
  button.close {
    line-height: 1rem;
  }

  &--danger {
    padding-left: 3rem;
    &:before {
      content: url("../icons/icon-error.svg");
      position: absolute;
      left: 0.6rem;
      top: 0.6rem;
    }
  }
}

.invalid-feedback {
  &:before {
    content: $invalid-feedback-icon;
    vertical-align: -20%;
    padding-right: 5px;
    width: 21px;
    height: 16px;
    display: inline-block;
  }
}

.check-array,
.radio.array {
  .invalid-feedback:before {
    content: none;
  }
}

.collapse-header {
  font-size: 16px;
  font-weight: bold;
  color: $blue;

  * + & {
    margin-top: 2rem;
  }
}

// Modal custom style
.modal-header {
  background: $custom-grey5;

  .close {
    background: $white;
    margin: 0;
    padding: 0 0.5rem;
    @extend .btn;
    @extend .btn-outline-secondary;

    span {
      font-size: 1.6rem;
    }
  }
}

.modal-title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5;
}

// form controls with no label
.form-group.col .custom-checkbox:only-child,
.form-control-no-label {
  margin-top: 2rem;
}

// Style datatable pagination
.pagination {
  display: flex;
  justify-content: flex-end;
}

.react-bs-table-sizePerPage-dropdown,
.dropdown-select {
  button {
    @extend .btn-outline-secondary;
    @extend .btn-white;
  }
}

.dropdown-select__menu {
  min-width: 3em;
}

// Hide test label
.test-label {
  display: none;
}

// Show test label
.show-test-label {
  .test-label {
    line-height: 30px;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    text-align: center;
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
  }

  nav.breadcrumb-container {
    margin-top: 90px;
  }

  header.navbar {
    top: 30px;
  }
}

.clickable {
  cursor: pointer;
}
