@import "../../node_modules/react-day-picker/lib/style";

.DayPickerInput {
  width: 100%;

  &-Overlay {
    top: 5px;
    line-height: initial;

    &:focus {
      outline: none;
    }
  }

  &-OverlayWrapper select {
    width: auto !important;
    min-width: 0!important;
  }
}

.DayPicker {
  min-width: 238px;
  outline: none;

  &-Day, &-Weekdays, &-Caption, &-Footer {
    line-height: initial;
  }

  &-Footer {
    text-align: center;
  }

  &-FooterCustom {
    border-top: 1px solid rgba(#004b93, .5);
    padding: 15px 10px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-Confirm {
      margin: 0 !important;
    }

    &-Reset {
      margin: 0 !important;
    }

    &-Label {
      font-weight: normal;
      line-height: 26px;
      margin-top: -5px;
      margin-bottom: 5px;
      color: #004b93;
      width: 100%;
    }
  }

  &-Weekday {
    color: #004b93;
    font-weight: bold;
  }

  &-Day {
    font-weight: bold;

    &--selected:not(&--disabled):not(&--outside) {
      border-radius: 3px;
      background-color: #004b93;

      &:hover {
        background-color: #004b93;
      }
    }

    &--today {
      color: #004b93;
    }

    &--outside{
      color: #004b93;
      font-weight: normal;
    }

    &--disabled{
      color: #004b93;
    }
  }

  &-Caption {
    select {
      line-height: 25px;
      height: 25px;
      background-color: initial;
      border-color: initial;

      &:last-child {
        margin-left: 5px;
      }
    }
  }

  &-TodayButton{
    color: #004b93;
  }

}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):focus {
  outline: none;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: rgba(#004b93, 0.1);
  border-radius: 3px;
}
