table.contacts {
  tr {

    // Phones
    th:nth-child(4),
    th:nth-child(3),
    td:nth-child(4),
    td:nth-child(3) {
      width: 130px;
    }

    // Email
    th:nth-child(5),
    td:nth-child(5) {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // Note
    th:last-child, td:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.vacation-show-item {
  margin-bottom: 0.5rem;
}

.vert-top {
  vertical-align: top;
}