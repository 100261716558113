.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: change-color($white, $alpha: 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
