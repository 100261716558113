.configuration {
  h3 {
    margin: 2rem 0;
  }

  .sublabel {
    margin: 0.7rem 0;
    color: #677078;
  }

  .row.active {
    margin-bottom: 1rem;

    .form-group {
      display: inline-block;
      margin-right: 2rem;
    }
  }

  .row.active + .infolabel {
    margin-top: -1.5rem;
    margin-bottom: 2rem;
    line-height: 32px;
    color: #677078;

    svg {
      margin-right: 5px;
      margin-left: -3px;
    }
  }

  .vacation-item {
    .btn-link {
      text-decoration: none;
      color: #d40511;

      &:focus {
        border: 0;
        box-shadow: none;
      }
    }
  }
}
