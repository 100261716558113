@import "../../assets/themes/default/variables";

th.parcelBoxState {
  width: 120px;
}

th.parcelBoxPsName {
  width: 260px;
}

p.icpDescription {
  color: $custom-grey1;
  font-size: 14px;
  margin-bottom: 30px;
}
