.wizard-steps {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;

  .nav-item {
    background-color: $list-group-bg;
    border-radius: 0;

    &:first-child,
    &:first-child .nav-link {
      @include border-left-radius($list-group-border-radius);
    }

    &:last-child,
    &:last-child .nav-link {
      @include border-right-radius($list-group-border-radius);
    }

    .nav-link {
      padding: 1.8rem 1rem;
      border-radius: 0;
      border: $list-group-border-width solid $list-group-border-color;

      &:not(:first-child) {
        border-left: none;
      }

      &:not(.active) {
        color: $list-group-action-color;
      }
    }
  }

  .list-group-item {
    flex: 1 1 auto;
    padding: 1.8rem 1rem;
    text-align: center;
    font-weight: 400;

    i {
      @extend .rounded;
      border: 1px solid $custom-grey1;
      padding: 3px 7px;
      margin-right: 0.7rem;
      font-style: normal;
      font-size: 90%;

      &:before {
        content: attr(step-index);
      }
    }

    &.list-group-item-primary {
      color: $white;
      background: $blue;

      i {
        background: $white;
        color: $blue;
        border: none;
      }
    }

    &.list-group-item-success {
      color: $blue;
      background: adjust-color($blue, $alpha: -0.94);

      i {
        background: $white;
        &:before {
          content: "\2713";
        }

        border-color: adjust-color($blue, $alpha: -0.6);
      }
    }
  }
}
