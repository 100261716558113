.wrapper-spinner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    width: 150px;
    height: auto;
  }
}

.react-bs-table-sizePerPage-dropdown.dropdown {
  .dropdown-menu {
    min-width: 3rem;

    li {
      width: initial;
      padding: initial;
      clear: initial;

      &:hover {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
      }

      & > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

header.navbar {
  .show.dropdown.nav-item {
    .dropdown-menu {
      border-radius: 0 0 8px 8px;
      box-shadow: 0 0 16px 0 rgba(31, 34, 38, 0.1), 0 4px 8px 0 rgba(31, 34, 38, 0.1), 0 1px 2px 0 rgba(31, 34, 38, 0.1);
      background-color: white;
      border: 0px;

      .dropdown-item {
        padding: 0.45rem 2rem 0.45rem 1.5rem;
        border-left: 4px solid white;

        &.active, &:active {
          background-color: transparent;
          border-left: 4px solid #004b93;
          color: #1f2226;

          &:hover, &:focus {
            border-left: 4px solid #004b93;
            background-color: #f8f8f8;
          }
        }

        &:hover, &:focus {
          border-left: 4px solid #f8f9fa;
        }
      }
    }
  }
}

.btn-toolbar .btn + .btn {
  margin-left: 1rem;
}
