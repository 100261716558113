@import "../../assets/themes/default/variables";

.pointCard {
  padding: 40px 96px;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
    label {
      font-size: 1.75rem;
      color: $blue;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      padding: 3px 0px;

      img {
        cursor: pointer;
      }
    }
  }

  &:hover {
    div {
      label {
        border-bottom: 2px solid $blue;
      }
    }
  }
}
