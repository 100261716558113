.statistics {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  .recharts-legend-wrapper {
    width: initial !important;

    ul.recharts-default-legend {
      width: initial !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left !important;

      svg.recharts-surface {
        border-radius: 10px;
        transform: scale(1, 0.4);
      }
    }
  }

  .no-data {
    border-radius: 50%;
    background-color: #F0F1F2;
    color: #677078;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 50px;
    left: 70px;

    @media (min-width: 768px) {
      left: 0px;
    }

    @media (min-width: 1200px) {
      left: 30px;
    }
  }

  .legend {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    .legend-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      > span {
        width: 24px;
        height: 8px;
        border-radius: 20px;
        background-color: #bababa;
        margin-right: 4px;
      }
    }
  }

  .statistics-tooltip {
    padding: 8px 16px;
    background-color: #1f2226;
    color: #ffffff;
    border-radius: 4px;
    > label {
      color: #ffffff;
      font-size: 14px;
      line-height: 17px;
    }

    > span {
      font-size: 20px;
      line-height: 24px;
      display: block;
    }
  }

  .clickable {
    cursor: pointer;
  }
}
