.vue-treeselect--disabled {
  .vue-treeselect__control {
    background-color: #e9ecef;
  }
}

.treeList-error-container {
  .vue-treeselect__control {
    border-color: #d40511;
    &:hover {
      border-color: #d40511 !important;
    }
  }
}
