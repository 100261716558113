@import "../assets/themes/default/variables";

.empty-search-results {
  padding: 3em 0 1em;
  color: $custom-grey1;

  &__icon {
    margin: 1rem auto;
    width: 72px;
    height: 72px;
    background: $custom-grey5 url("../assets/icons/icon-search.svg") no-repeat center center;
    background-size: 50% auto;
    border-radius: 50%;
  }

  &__text {
    text-align: center;
  }
}
