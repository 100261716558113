.copy_icon {
  width: 20px;
  height: 20px;
  background: url("../../../../assets/icons/icon-copy.svg") no-repeat center center;
  display: inline-block;
  background-size: 16px 18px;
  padding: 12px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    background-color: #e3e5e7;
  }
}

.parcel_number {
  display: flex;
  flex-direction: inherit;
  align-items: center;

  .number {
    max-width: calc(100% - 30px);
    overflow-wrap: break-word;
    white-space: break-spaces;

    span:nth-last-child(2), span:nth-last-child(1) {
      overflow-wrap: initial;
    }
  }
}
