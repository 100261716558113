.wrapper-login {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 3em 0;
}

.form-login {
  width: 100%;
  max-width: 25em;
  padding: 1em;
  margin: auto;
}
