.paymentTableHeader {
  tr {
    th {
      width: 25%;
    }
  }
}
tbody {
  .price {
    text-align: right;
    padding-right: 170px;
  }
}
