.mineMemosTableWrapper {
  > .react-bootstrap-table {
    overflow: inherit !important;
    td.memoStatus {
      overflow: inherit !important;

      .dropdown-toggle {
        border: 1px solid transparent;
        border-radius: 5px;
        color: #1F2226;
        width: min-content;

        &:after {
          margin-left: 8px;
        }

        &.btn:focus, &.btn:hover {
          border-color: #004B93;
          text-decoration: none;
          box-shadow: none;
        }
      }

      .dropdown-menu {
        padding: 0;

        .dropdown-item {
          padding: 10px 12px;

          &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &:hover {
            background-color: #F9F9FA;
          }
        }
      }
    }

    tr.after-deadline:nth-child(even) {
      background-color: #F7EDEE;
    }

    tr.after-deadline:nth-child(odd) {
      background-color: #F5E1E3;
    }
  }
}
